import React, { useEffect } from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import logo from './image/pukurojo.png';


const Home = () => {
  // Función para aplicar efectos de parallax
  const applyParallaxEffects = () => {
    const text = document.getElementById('text');
    const bird1 = document.getElementById('bird1');
    const bird2 = document.getElementById('bird2');
    const btn = document.getElementById('btn');
    const rocks = document.getElementById('rocks');
    const forest = document.getElementById('forest');
    const header = document.getElementById('header');

    const onScroll = () => {
      const value = window.scrollY;
      text.style.top = `${50 + value * -0.1}%`;
      bird2.style.top = `${value * -1.5}px`;
      bird2.style.left = `${value * 2}px`;
      bird1.style.top = `${value * -1.5}px`;
      bird1.style.left = `${value * -5}px`;
      btn.style.marginTop = `${value * 1.5}px`;
      rocks.style.top = `${value * -0.12}px`;
      forest.style.top = `${value * 0.25}px`;
      header.style.top = `${value * 0.5}px`;
    };

    window.addEventListener('scroll', onScroll);

    // Limpieza para evitar múltiples escuchas de eventos cuando el componente se desmonta
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  };

  useEffect(() => {
    applyParallaxEffects(); // Aplica el efecto parallax cuando el componente se monta
  }, []);

  return (
    <div>
      <header id="header">
      <a href="https://puku.ar" className="logo">
      <img src={logo} alt="Puku Logo" className="pukurojo.png" /> {/* Agrega el logo */}
    </a>
        <ul>
          <li><Link to="/" className="active">Home</Link></li>
          <li><a href="https://puku-0q89.onrender.com/">Comprar</a></li>
        </ul>
      </header>

      <section>
        <h2 id="text">
          <span>Capturamos tus momentos deportivos más importantes</span>
          <br />
          
        </h2>
        {<img src="https://user-images.githubusercontent.com/65358991/170092504-132fa547-5ced-40e5-ab64-ded61518fac2.png" id="bird1" alt="Bird 1 flying" /> }
        <img src="https://user-images.githubusercontent.com/65358991/170092542-9747edcc-fb51-4e21-aaf5-a61119393618.png" id="bird2" alt="Bird 2 flying" />
        <img src="https://user-images.githubusercontent.com/65358991/170092559-883fe071-eb4f-4610-8c8b-a037d061c617.png" id="forest" alt="Forest background" />
        <a href="https://puku-0q89.onrender.com/" id="btn">COMPRAR</a>
        <img src="https://user-images.githubusercontent.com/65358991/170092605-eada6510-d556-45cc-b7fa-9e4d1d258d26.png" id="rocks" alt="Rocks in a landscape" />
        <img src="https://user-images.githubusercontent.com/65358991/170092616-5a70c4af-2eed-496f-bde9-b5fcc7142a31.png" id="water" alt="Water in a landscape" />
        
      </section>

      <div className="sec">
        <h2>.</h2>
        <p>
         .
        </p>
      </div>
    </div>
  );
};

export default Home;
