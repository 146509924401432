import React from 'react';
import './Footer.css'; // Asegúrate de tener el archivo CSS para estilizar el footer

// Importa imágenes para íconos de redes sociales
import facebookIcon from './image/facebook.png';
import instagramIcon from './image/instagram.png';
import linkedinIcon from './image/linkedin.png';
import youtubeIcon from './image/youtube.png';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                {/* Íconos de redes sociales */}
                <div className="social-media-icons">
                    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="icon">
                        <img src={facebookIcon} alt="Facebook" />
                    </a>
                    <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="icon">
                        <img src={instagramIcon} alt="Instagram" />
                    </a>
                    <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="icon">
                        <img src={linkedinIcon} alt="LinkedIn" />
                    </a>
                    <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer" className="icon">
                        <img src={youtubeIcon} alt="YouTube" />
                    </a>
                </div>

                {/* Enlaces a otras páginas o secciones */}
                <div className="footer-links">
                    
                    <a href="https://puku.ar/trewa" className="footer-link">Administrador</a>
                </div>

                {/* Copyright */}
                <p className="copyright">
                    &copy; 2024 Suriyaco Valley. Todos los derechos reservados. Puku es propiedad de TREWA.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
