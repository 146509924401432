import React, { useEffect } from 'react';
import Home from './Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css'; // Estilos globales
import Footer from './Footer';

//import NavBar from './NavBar'; // Importa el NavBar */



// Función para aplicar efecto parallax
const applyParallaxEffect = () => {
  const text = document.getElementById('text');
  const bird1 = document.getElementById('bird1');
  const bird2 = document.getElementById('bird2');
  const btn = document.getElementById('btn');
  const rocks = document.getElementById('rocks');
  const forest = document.getElementById('forest');
  const header = document.getElementById('header');

  const onScroll = () => {
    const value = window.scrollY;
    text.style.top = `${50 + value * -0.1}%`;
    bird2.style.top = `${value * -5.5}px`;
    bird2.style.left = `${value * 2}px`;
    bird1.style.top = `${value * -1.5}px`;
    bird1.style.left = `${value * -5}px`;
    btn.style.marginTop = `${value * 1.5}px`;
    rocks.style.top = `${value * -0.12}px`;
    forest.style.top = `${value * 0.25}px`;
    header.style.top = `${value * 0.5}px`;
  };

  window.addEventListener('scroll', onScroll);

  // Limpieza para evitar múltiples escuchas de eventos cuando el componente se desmonte
  return () => {
    window.removeEventListener('scroll', onScroll);
  };
};

const App = () => {
  useEffect(() => {
    applyParallaxEffect(); // Aplica el efecto parallax cuando el componente se monta

    // La función de limpieza se ejecuta cuando el componente se desmonta
    return () => {
      applyParallaxEffect(); // Limpia el evento de scroll
    };
  }, []); // Solo se ejecuta una vez al montar el componente

  return (

    <Router>
      
      <div className="App">
      
      {/* <NavBar /> {/* Añade el NavBar al comienzo */} 
        <Routes>
          <Route path="/" element={<Home />} /> {/* Ruta para Home */}
          {/* Puedes agregar más rutas aquí */}
        </Routes>
      </div>
      <Footer />
      
    </Router>
    
  );
};

export default App;
